import React from "react";
import styles from "@aspen/theme/Home.module.less";
import { getVentureList } from "@aspen/services";
import { IInjectProps, IVentureListItem } from "@aspen/model";
import dynamic from "next/dynamic";
import { withRouter } from "next/router";
import {
  i18nUtil,
  reportEvent,
  certifiedKycLevel,
  GA_EVENT_NAME,
  HOME_PATH,
  VENTURES_PATHS
} from "@aspen/libs";
import { isEqual } from "lodash-es";
import { NotKyc2Mask } from "./DynamicImport";

interface IProps extends IInjectProps {}
interface IState {
  ventureList: IVentureListItem[];
}
const VentureItem = dynamic(() => import("../venture/VentureItem/VentureItem"), {
  loading: () => <></>
});

class WellVenture extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ventureList: []
    };
    this.handleClickOnViewAll = this.handleClickOnViewAll.bind(this);
  }

  handleClickOnViewAll(): void {
    reportEvent({ moduleName: GA_EVENT_NAME.home.viewAllVenture });
    this.props.router.push(VENTURES_PATHS.VENTURES);
  }

  componentDidMount(): void {
    // 默认不分页 显示全部策略
    let params = {
      limit: 100,
      nextToken: null
    };
    getVentureList(params).then((res) => {
      if (res?.code == "0") {
        this.setState({ ventureList: res?.data?.rows ?? [] });
      }
    });
  }
  shouldComponentUpdate(nextProps, nextState): boolean {
    return !isEqual(nextState.ventureList, this.state.ventureList);
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const { ventureList } = this.state;
    return (
      <div>
        {ventureList && ventureList.length > 0 && (
          <section className={styles.wellVenture}>
            {!certifiedKycLevel(2) && <NotKyc2Mask />}
            <div style={{ filter: certifiedKycLevel(2) ? "" : "blur(2px)" }}>
              <p className={styles.recommendedTitle}>{intl["overview.venture.title"]}</p>
              <div className={styles.ventures}>
                {ventureList.slice(0, 3).map((item) => (
                  <div className={styles.ventureListItem} key={item.id}>
                    <VentureItem data={item} key={item.id} fromPage={HOME_PATH} />
                  </div>
                ))}
              </div>
              <p className={styles.viewAll} onClick={this.handleClickOnViewAll}>
                {intl["overview.venture.view.all"]} &gt;
              </p>
            </div>
          </section>
        )}
      </div>
    );
  }
}
export default withRouter(WellVenture);
